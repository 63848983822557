import React from 'react';
import style from '../../layouts/MainLayout/style.css';
import Footer from 'cccisd-footer';

const AppFooter = () => {
    return (
        <div className={style.footer}>
            <Footer className={style.footerContent} showContactUsLink={false} />
        </div>
    );
};

export default AppFooter;
