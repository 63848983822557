import React from 'react';
import style from './style.css';

const QuestLayout = props => {
    return (
        <div className={style.wrapper}>
            <div className={style.body}>
                <div className={props.className}>{props.children}</div>
            </div>
        </div>
    );
};
export default QuestLayout;
