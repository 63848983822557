import React from 'react';
import style from '../DeploymentCenter/style.css';

const ComingSoon = () => {
    let pathname = window.location.pathname;

    switch (pathname) {
        case '/administer':
            pathname = 'Administer';
            break;
        case '/results/:assignmentPlanId/:pawnId':
            pathname = 'Results';
            break;
        case '/flags':
            pathname = 'Flags';
            break;
        default:
            pathname = 'Results';
            break;
    }

    return (
        <div>
            <div className={style.panel}>
                <div className={style.panelLeft}>
                    <div className={style.panelHead}>
                        <div className={style.pageTitle}>{pathname}</div>
                    </div>
                    <div className={style.panelBody}>
                        <div className={style.pageDescription}>
                            <span className={style.descriptionText}>Coming Soon!</span>
                        </div>
                    </div>
                </div>
                <div className={style.panelRight} />
            </div>
        </div>
    );
};

export default ComingSoon;
