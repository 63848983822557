import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import Header from '../../components/Header';
import AppFooter from '../../components/AppFooter';

export default class MainLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: 'container',
    };

    render() {
        return (
            <div className={style.wrapper}>
                <Header className={this.props.className} />
                <div className={style.body}>
                    <div className={this.props.className}>{this.props.children}</div>
                </div>

                <AppFooter />
            </div>
        );
    }
}
