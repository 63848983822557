import React from 'react';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';

const Fortress = window.cccisd.fortress;

const Course = () => {
    return (
        <DeploymentPlayer
            deploymentHandle="main"
            pawnId={Fortress.user.acting.id}
            pawnHash={Fortress.user.acting.random_hash}
            onComplete={() => {}}
        />
    );
};

export default Course;
