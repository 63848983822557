import React, { useState, useEffect } from 'react';
import axios from 'cccisd-axios';
import RolePickerGroup from './RolePickerGroup';
import Loader from 'cccisd-loader';
import style from './style.css';
import _pick from 'lodash/pick';

import DistrictAdmin from './images/DistrictAdmin.png';
import SchoolAdmin from './images/SchoolAdmin.png';
import StateAdmin from './images/StateAdmin.png';
import EntityAdmin from './images/EntityAdmin.png';
import SiteAdmin from './images/SiteAdmin.png';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

const RolePicker = () => {
    const [loading, setLoading] = useState(true);
    const [userGroups, setUserGroups] = useState([]);

    useEffect(() => {
        getUserGroups();
        setLoading(false);
    }, []);

    const getUserGroups = async () => {
        if (!userGroups.length > 0) {
            let response = await axios.get(Boilerplate.route('all.pawn.roles'));
            const userGroupData = response.data.roles.map(role => {
                return _pick(role, ['id', 'random_hash', 'data_type', 'group']);
            });

            setUserGroups(userGroupData);
        }
    };

    function compareGroupLabel(a, b) {
        if (a.group.label.toLowerCase() < b.group.label.toLowerCase()) {
            return -1;
        }
        if (a.group.label.toLowerCase() > b.group.label.toLowerCase()) {
            return 1;
        }
        return 0;
    }

    let instructorPawns = userGroups.filter(user => user.data_type === 'instructor');
    instructorPawns.sort(compareGroupLabel);

    let supervisorPawns = userGroups.filter(user => user.data_type === 'supervisor');
    supervisorPawns.sort(compareGroupLabel);

    let districtPawns = userGroups.filter(user => user.data_type === 'guAdmin');
    districtPawns.sort(compareGroupLabel);

    let statePawns = userGroups.filter(user => user.data_type === 'orgAdmin');
    statePawns.sort(compareGroupLabel);

    let schoolAdminPawns = userGroups.filter(user => user.data_type === 'siteAdmin');
    schoolAdminPawns.sort(compareGroupLabel);

    let uberPawns = userGroups.filter(user => user.data_type === 'uberadmin');
    uberPawns.sort(compareGroupLabel);

    let orgInstructorPawns = userGroups.filter(user => user.data_type === 'orgInstructor');
    orgInstructorPawns.sort(compareGroupLabel);

    let orgSupervisorPawns = userGroups.filter(user => user.data_type === 'orgSupervisor');
    orgInstructorPawns.sort(compareGroupLabel);

    if (loading) {
        return <Loader loading />;
    }
    return (
        <div>
            {instructorPawns.length > 0 || supervisorPawns.length > 0 ? (
                <div className={style.subheading}>
                    <span className={style.subheadingTitle}>Please choose which account you would like to visit</span>
                </div>
            ) : (
                <div className={style.subheading}>
                    <span className={style.subheadingTitle}>
                        Your accounts will appear here when you join one or more teams.
                    </span>
                </div>
            )}

            <div className={style.groupSelectWrapper}>
                {instructorPawns.length >= 1 && (
                    <RolePickerGroup groupPawns={instructorPawns} backgroundImage={SchoolAdmin} />
                )}
                {supervisorPawns.length >= 1 && (
                    <RolePickerGroup groupPawns={supervisorPawns} backgroundImage={DistrictAdmin} />
                )}
                {districtPawns.length >= 1 && (
                    <RolePickerGroup groupPawns={districtPawns} backgroundImage={EntityAdmin} />
                )}
                {statePawns.length >= 1 && <RolePickerGroup groupPawns={statePawns} backgroundImage={StateAdmin} />}
                {schoolAdminPawns.length >= 1 && (
                    <RolePickerGroup groupPawns={schoolAdminPawns} backgroundImage={SchoolAdmin} />
                )}
                {uberPawns.length >= 1 && <RolePickerGroup groupPawns={uberPawns} backgroundImage={SiteAdmin} />}
                {orgInstructorPawns.length >= 1 && (
                    <RolePickerGroup groupPawns={orgInstructorPawns} backgroundImage={SchoolAdmin} />
                )}
                {orgSupervisorPawns.length >= 1 && (
                    <RolePickerGroup groupPawns={orgSupervisorPawns} backgroundImage={DistrictAdmin} />
                )}
            </div>
        </div>
    );
};

export default RolePicker;
