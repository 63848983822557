import React from 'react';
import axios from 'cccisd-axios';
import style from '../style.css';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;
const Fortress = window.cccisd && window.cccisd.fortress;

const RolePickerGroup = ({ groupPawns, backgroundImage }) => {
    const loginAs = async user => {
        const response = await axios.post(Boilerplate.route('api.nexus.user.changeTo'), user);
        if (response.data.status === 'success') {
            window.location.href = Boilerplate.url(Fortress.settings.config.after_login_url);
        }
    };

    const getAccountLabel = pawns => {
        const handle = pawns[0].data_type;
        if (handle === 'uberadmin') {
            return 'SHARE Admin';
        }
        if (handle === 'instructor') {
            return 'Provider';
        }
        if (handle === 'orgInstructor') {
            return 'Org Provider';
        }
        if (handle === 'orgSupervisor') {
            return 'Org Supervisor';
        }
        return handle;
    };

    const accountLabel = getAccountLabel(groupPawns);

    return (
        <div className={style.groupSelect}>
            <div className={style.image} style={{ backgroundImage: `url(${backgroundImage})` }} />
            <div className={style.flagWrap}>
                <div className={style.flag} />
            </div>
            <div className={style.groupText}>{accountLabel} Accounts</div>
            {groupPawns &&
                groupPawns.map((pawn, index) => {
                    return (
                        <button
                            key={index}
                            type="button"
                            className={`btn btn-lg btn-primary ${style.button}`}
                            onClick={() =>
                                loginAs({
                                    pawn: pawn.id,
                                    pawnHash: pawn.random_hash,
                                })
                            }
                        >
                            {pawn.group ? pawn.group.label : 'SHARE Admin'}
                        </button>
                    );
                })}
        </div>
    );
};

export default RolePickerGroup;
